import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import ReportsCliente from '../reportsCliente/reportsCliente';

export default props => {

	return (
		<div id='id-content-wrapper' className='content-wrapper' style={{ paddingBottom: 0 }} >
			<Switch>

				<Route exact path='/' component={ReportsCliente} />

				<Redirect from='*' to='/' />
			</Switch>
		</div>
	);
	
};
