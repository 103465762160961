import React from 'react'

export default props => (
    <th {...props} className={props.acoes ? 'table-actions' : ''}
		style={!props.sticky ? {
			textAlign: props.alignRight ? 'right' : (props.alignCenter ? 'center' : 'left'),
			verticalAlign: props.verticalAlign || "middle",
			width: props.width,
			height: props.height,
			paddingLeft: props.alignRight ? 0 : 2,
			paddingRight: props.alignRight ? 0 : 2,
			backgroundColor: props.backgroundColor,
			color: props.color,
			fontSize: props.fontSize
		} : {
			...props.style,			
			borderBottom: "none",
			textAlign: props.alignRight ? "right" : props.alignCenter ? "center" : "left",
			verticalAlign: props.verticalAlign || "middle",
			minWidth: props.minWidth,
			maxWidth: props.maxWidth || "fit-content",
			width: props.width,
			height: props.height,
			minHeight: props.minHeight,
			paddingTop: props.paddingTop,
			paddingBottom: props.paddingBottom,
			paddingLeft: props.alignRight ? 0 : 2,
			paddingRight: props.alignRight ? 0 : 2,
			color: props.color,
			backgroundColor: props.backgroundColor ? props.backgroundColor : props.sticky && "#fff",
			position: props.sticky && "sticky",
			top: props.top ? props.top : ( props.sticky && (props.top || -1)),
			boxShadow: props.sticky && "inset 0px -1px 1px 0px rgba(0,0,0,0.20)",
			fontSize: props.fontSize			
		}} >
		{props.children}
	</th>
)
